import './App.css';
import { Link, Route, Routes } from 'react-router-dom';
import Admin from './Components/Admin/Admin';
import Overview from './Components/projects/Overview';
import Demographics from './Components/projects/Demographics';

function App() {
  return (
    <div className="App">
      <nav>
        <Link to={'/'}>Main</Link>
        <Link to={'/overview'}>Overview</Link>
        <Link to={'/demographics'}>Demographics</Link>
      </nav>
      <div className='routes'>
        <Routes>
          <Route index path='/' element={<Admin />} />
          <Route path='/overview' element={<Overview />} />
          <Route path='/demographics' element={<Demographics />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import './projects.scss';


ChartJS.register(ArcElement, Tooltip);

const dataForDoughnut = {
    labels: ['Male', 'Female'],
    datasets: [
        {
            label: 'Percentage',
            data: [50, 50],
            backgroundColor: [
                'orange',
                'yellow',
            ],
            borderWidth: 0,
        },
    ],
};
const dataForDoughnutTwo = {
    labels: ['Full-time', 'Part-time', 'casual'],
    datasets: [
        {
            label: 'Percentage',
            data: [33, 33, 33],
            backgroundColor: [
                'orange',
                'yellow',
                'cyan',
            ],
            borderWidth: 0,
        },
    ],
};
const chartTwo = [
    { month: 'jan', number: 31 },
    { month: 'feb', number: 14 },
    { month: 'mar', number: 123 },
    { month: 'apr', number: 122 },
    { month: 'may', number: 87 },
    { month: 'jun', number: 45 },
    { month: 'jul', number: 78 }
]
const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
        display: false
    },
    tooltips: {
        callbacks: {
            label: function (tooltipItem) {
                return tooltipItem.yLabel;
            }
        }
    }
};

const Demographics = () => {

    return (
        <div className="demographics_panel">
            <div className='line'>
                <div className='doughnut_wrapper'>
                    <h3>GENDER</h3>
                    <Doughnut options={options} data={dataForDoughnut} />
                </div>
                <div className='horizontal_chart_wrapper'>
                    <h3>AGE</h3>
                    <div className="chart">
                        <div className="chart_x">
                            <div className="x_div">
                                {chartTwo.map((single, _index) => (
                                    <span key={_index}>{single.month}</span>
                                ))}
                            </div>
                            <div className="main_chart">
                                {chartTwo.map((single, _index) => (
                                    <div title={single.number} key={_index} style={{ width: `${single.number / 160 * 100}%`, backgroundColor: 'red' }}></div>
                                ))}
                            </div>
                        </div>
                        <div className="chart_y">

                            {[0, 20, 40, 60, 80, 100, 120, 140, 160].map((x) => (
                                <span key={x}>{x}</span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='line'>
                <div className='doughnut_wrapper'>
                    <h3>EMPLOYMENT</h3>
                    <Doughnut options={options} data={dataForDoughnutTwo} />
                </div>
                <div className='horizontal_chart_wrapper'>
                    <h3>AREAS</h3>
                    <div className="chart">
                        <div className="chart_x">
                            <div className="x_div">
                                {chartTwo.map((single, _index) => (
                                    <span key={_index}>{single.month}</span>
                                ))}
                            </div>
                            <div className="main_chart">
                                {chartTwo.map((single, _index) => (
                                    <div title={single.number} key={_index} style={{ width: `${single.number / 160 * 100}%`, backgroundColor: 'red' }}></div>
                                ))}
                            </div>
                        </div>
                        <div className="chart_y">

                            {[0, 20, 40, 60, 80, 100, 120, 140, 160].map((x) => (
                                <span key={x}>{x}</span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='line' >
                <div className='horizontal_chart_wrapper'>
                    <h3>LOCATION</h3>
                    <div className="chart">
                        <div className="chart_x">
                            <div className="x_div">
                                {chartTwo.map((single, _index) => (
                                    <span key={_index}>{single.month}</span>
                                ))}
                            </div>
                            <div className="main_chart">
                                {chartTwo.map((single, _index) => (
                                    <div title={single.number} key={_index} style={{ width: `${single.number / 160 * 100}%`, backgroundColor: 'red' }}></div>
                                ))}
                            </div>
                        </div>
                        <div className="chart_y">

                            {[0, 20, 40, 60, 80, 100, 120, 140, 160].map((x) => (
                                <span key={x}>{x}</span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Demographics
import React from 'react'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import './projects.scss'
const Overview = () => {
    const chart = [
        { month: 'jan', number: 31 },
        { month: 'feb', number: 14 },
        { month: 'mar', number: 123 },
        { month: 'apr', number: 122 },
        { month: 'may', number: 87 },
        { month: 'jun', number: 45 },
        { month: 'jul', number: 78 },
        { month: 'aug', number: 37 },
        { month: 'sep', number: 89 },
        { month: 'oct', number: 92 },
        { month: 'nov', number: 105 },
        { month: 'dec', number: 100 }
    ]
    return (
    
        <div className='overview_panel' >
            <div className='upper_panel'>
                <div className="left_panel">
                    <div className='panel_rect'>
                        <div className="panel_icon">
                            <ArrowUpward className='up' />
                        </div>
                        <div className="panel_text">
                            <h5>Total Users</h5>
                            <h2>273<span>+15</span></h2>
                        </div>
                    </div>
                    <div className='panel_rect'>
                        <div className="panel_icon">
                            <ArrowDownward className='down' />
                        </div>
                        <div className="panel_text">
                            <h5>Total Users</h5>
                            <h2>273<span>+15</span></h2>
                        </div>
                    </div>
                    <div className='panel_rect'>
                        <div className="panel_icon">
                            <ArrowUpward className='up' />
                        </div>
                        <div className="panel_text">
                            <h5>Total Users</h5>
                            <h2>273<span>+15</span></h2>
                        </div>
                    </div>
                    <div className='panel_rect'>
                        <div className="panel_icon">
                            <ArrowDownward className='down' />
                        </div>
                        <div className="panel_text">
                            <h5>Total Users</h5>
                            <h2>273<span>+15</span></h2>
                        </div>
                    </div>
                </div>
                <div className="chart_panel">
                    <h3>Total Users</h3>
                    <div className="chart">
                        <div className="chart_x">
                            <div className="x_div">
                                {[160, 140, 120, 100, 80, 60, 40, 20, 0].map((x) => (
                                    <span key={x}>{x}</span>
                                ))}
                            </div>
                            <div className="main_chart">
                                {chart.map((single, _index) => (
                                    <div title={single.number} key={_index} style={{ height: `${single.number / 160 * 100}%`, backgroundColor: 'red' }}></div>
                                ))}
                            </div>
                        </div>
                        <div className="chart_y">
                            {chart.map((single, _index) => (
                                <span key={_index}>{single.month}</span>
                            ))}
                        </div>
                    </div>
                </div>


            </div>
            <div className="bottom_panel">
                <div className='bottom_activities'>
                    <h2>POST ACTIVITES</h2>
                    <div className='rect_info'>
                        <div className='rect_activity'>
                            <span>BlaBlaBla</span>
                            <hr />
                            <h5>1270</h5>
                        </div>
                        <div className='rect_activity'>
                            <span>BlaBlaBla</span>
                            <hr />
                            <h5>1270</h5>
                        </div>
                        <div className='rect_activity'>
                            <span>BlaBlaBla</span>
                            <hr />
                            <h5>1270</h5>
                        </div>
                    </div>
                </div>
                <div className='bottom_activities'>
                    <h2>POST ACTIVITES</h2>
                    <div className='rect_info'>
                        <div className='rect_activity'>
                            <span>BlaBlaBla</span>
                            <hr />
                            <h5>1270</h5>
                        </div>
                        <div className='rect_activity'>
                            <span>BlaBlaBla</span>
                            <hr />
                            <h5>1270</h5>
                        </div>
                        <div className='rect_activity'>
                            <span>BlaBlaBla</span>
                            <hr />
                            <h5>1270</h5>
                        </div>
                    </div>
                </div>
                <div className='bottom_activity_half'>
                    <div className="half_rect">
                        <span>Total impressions</span>
                        <h4>25,234</h4>
                    </div>
                    <div className="half_rect">
                        <span>Total impressions</span>
                        <h4>25,234</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview